import { BaseRoute } from './baseRoutes';
import { ROUTES } from './routes';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(
    prefix => location.href.indexOf(`${location.origin}${prefix}`) !== -1,
  );

const mfNavbar = (location: Location): boolean =>
  prefix(
    location,
    BaseRoute.PATH + ROUTES.CONTACT,
    BaseRoute.PATH + ROUTES.SIMULATOR,
  );

const mfContact = (location: Location): boolean =>
  prefix(location, BaseRoute.PATH + ROUTES.CONTACT);

const main = (): boolean => true;

export default {
  main,
  mfNavbar,
  mfContact,
};
