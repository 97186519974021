// @ts-ignore
export enum ROUTES {
  CONTACT = '',
  SIMULATOR = 'simulador',
}

export enum GO_TO_ROUTES {
  CONTACT = ROUTES.CONTACT,
  SIMULATOR = ROUTES.SIMULATOR,
}
