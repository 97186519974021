import 'babel-polyfill';
import { start, registerApplication } from 'single-spa';
import Activity from './activityFns';
import * as serviceWorker from './serviceWorker';

import { BaseRoute } from './baseRoutes';
import { GO_TO_ROUTES } from './routes';

const registerApps = (): void => {
  registerApplication(
    'main',
    () => import('./microFrontEnds/app.lifecycles'),
    Activity.main,
  );
  registerApplication(
    'mfNavbar',
    () => import('./microFrontEnds/mfNavbar/mfNavbar.lifecycle'),
    Activity.mfNavbar,
    {
      logoGoTo: BaseRoute.PATH,
      baseUrl: BaseRoute.PATH,
    },
  );
  registerApplication(
    'mfContact',
    () => import('./microFrontEnds/mfFormContact/mfFormContact.lifecycle'),
    Activity.mfContact,
    {
      goTo: GO_TO_ROUTES.CONTACT,
    },
  );
};

registerApps();
start();

serviceWorker.unregister();
